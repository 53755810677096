<template>
  <div>
    <div v-if="logofile_admin_url">
      <img :src="logofile_admin_url" style=" object-fit: contain" logo_attribute="logo" width='191' height='47px'
        alt="Cit Grup" />
    </div>
    <div v-else>
      <img :src="require('@/assets/images/logo/logo.png')" style=" object-fit: contain" width='191' height='47px'
        alt="Cit Grup" />
    </div>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import axios from '@/axios'
let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/public`
export default {
  data() {
    return {
      logofile_admin_url: '',
    }
  },
  computed: {},
  methods: {
    getLogoUrlsForAdmin() {
      axios
        .get(baseURL + `/franchisees/getLogoUrlsForAdmin`)
        .then(response => {
          if (response.data) {
            this.logofile_admin_url = response.data.logofile_admin_url
          }
        })
    }
  },
  created() {
    this.getLogoUrlsForAdmin()

  }
}
</script>

<style lang="css"></style>
